import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Card, Container } from "react-bootstrap";

import { useParams } from "react-router";
import ReportSetions from "./ReportSections";

const Reports = () => {
  let { id } = useParams<{ id?: string }>();
  const loadedReports = ReportSetions;
  const [section, setSection] = useState("");
  const toggle = (obj: string) => {
    setSection(obj);
  };

  const [t] = useTranslation("global");

  return (
    <>
      {/* {section === '606' ? <Customer toggle={toggle} show={true} /> : null} */}
      {/* {section === "MostSoldProduct" && (
        <MostSoldProduct toggle={toggle} show={true} />
      )} */}

      <Container>
        <Row className="my-4">
          <Col xs={12}>
            <h5 className="display-4">
              <i className="fas fa-file-invoice"></i>&nbsp;
              {t("reports")}
            </h5>
            <Row>
              <Col xs={12}>
                {Array.isArray(loadedReports) &&
                  loadedReports.map((mainSection, index) => {
                    if (
                      mainSection.requiredParent &&
                      mainSection.parentId !== id
                    )
                      return null;
                    return (
                      <Card className="shadow-sm mb-2" key={index}>
                        <Card.Header>
                          <Card.Title>{t(mainSection.name)}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <ul className="nav nav-pills nav-fill  flex-column ">
                            {mainSection.sections.map(
                              (subSection, subSectionIndex) => {
                                return (
                                  <li
                                    className="nav-item "
                                    key={subSectionIndex}
                                  >
                                    <button
                                      className={
                                        " btn-block btn  text-left " +
                                        (section === subSection.reference
                                          ? "btn-primary"
                                          : "btn")
                                      }
                                      onClick={() => {
                                        toggle(subSection.reference);
                                      }}
                                    >
                                      {t(subSection.label)}
                                    </button>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </Card.Body>
                      </Card>
                    );
                  })}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Reports;

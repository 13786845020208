import { parseJwt } from "./helpers";


//Get current user
const GetCurrentUser = () => {
    let user = localStorage.getItem("LogedUser");
    return user;
}

export const parsedUser = () => {
    let user = GetCurrentUser();
    let parsed = parseJwt(user);
    console.log("parsed user", parsed);
    return parsed;
}
export default GetCurrentUser;
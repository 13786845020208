import React, { useEffect, useState } from "react";
import QuickAddEditMetter from "../components/Metters/QuickAddEditMetter";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { parsedUser } from "../common/GetCurrentUser";
import { Routes } from "../routes";

const InventoryPage = () => {
	const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
	const user = parsedUser();
  const { id } = useParams();
  const [totalMeters, setTotalMeters] = useState(0);
  const history = useHistory();
  const SumMeter = (obj) => {

    let count = 0;
    Array.isArray(obj) && obj.forEach((item) => {
      let amount = item.amount - item.previousAmount;
      count += amount;
    });

    setTotalMeters(count);
  };  

  useEffect(() => {
	console.log(id)
	  if(!id || id===':id'){
		history.push(Routes.Inventory.clean + moment().format('YYYY-MM-DD'))
	  }
  },[id])
	return <Container>
		<Row>
		<Col className="col-auto">
          
          <input
            type="date"
            className="form-control d-inline"
            value={date}
            onChange={(e) => {
           //   history.push(Routes.Dashboard.clean + moment(e.target.value).format('YYYY-MM-DD'))
                  setDate(e.target.value);
            }}
          />
        </Col>
        <Col className="col-auto">
        <button className="btn btn-primary" onClick={() => {

          history.push(Routes.Inventory.clean + moment(date).format('YYYY-MM-DD'))

        }}>
<i className="fa fa-search"></i>
        </button>
        </Col>
		<Col xs={12} md={12}>
              <QuickAddEditMetter date={date} SumMetter={SumMeter} />

            </Col >
          
		</Row>
	</Container>;
};

export default InventoryPage
import React from "react";

import SalesComponent from "../components/Sales/SalesComponent";
import SalesRecordComponent from "../components/SalesRecord/SalesRecordComponent";

const SalesRecordPage = () => {
  return <SalesRecordComponent />;
};

export default SalesRecordPage;

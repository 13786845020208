import React, { useEffect, useState } from "react";

import API from "../common/API";
import { Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { NumberFormat } from "../common/helpers";

function TablePage(props) {
  const [calibrations, setCalibrations] = useState({
    metter8000: [],
    metter30000: [],
  });
  const [filter, setFilter] = useState(0);

  const requestCalibration = async () => {
    let request8000 = await API.getAction(`tank/calibrationTable?id=1`);
    let request30000 = await API.getAction(`tank/calibrationTable?id=2`);

    setCalibrations({
      metter8000: request8000.data.response,
      metter30000: request30000.data.response,
    });
    console.log(request8000);
  };

  useEffect(() => {
    requestCalibration();
  }, []);

  return (
    <Container className="">
      <Card className="shadow-sm my-4 bg-white">
        <Card.Header>
          <Card.Title>
            <h5>Filter</h5>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} md={4} className="mb-4">
              <label>Percentage</label>
              <input
                className="form-control"
                type="number"
                value={filter}
                onChange={(e: any) => setFilter(e.target.value)}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="shadow-sm my-4 bg-white">
        <Card.Body>
          <Row>
            <Col xs={12} md={6}>
              <h3>TANQUE 8,000</h3>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Percentage</th>
                      <th>Vol. Cal ( gls )</th>
                    </tr>
                  </thead>
                  <tbody>
                    {calibrations.metter8000
                      .filter((x: any) => x.percentage >= filter)
                      .map((item: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.percentage} %</td>
                            <td>{NumberFormat(item.volume)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <h3>TANQUE 30,000</h3>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Percentage</th>
                      <th>Volume</th>
                    </tr>
                  </thead>
                  <tbody>
                    {calibrations.metter30000

                      .filter((x: any) => x.percentage >= filter)
                      .map((item: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.percentage} %</td>
                            <td>{NumberFormat(item.volume)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default TablePage;

import React, { useEffect, useState } from "react"
import FieldSelector from "../FieldSelector"
import { useTranslation } from "react-i18next"
import CRUDField from "../genericCRUD/CRUDField"

const Filter = props => {
  const [fields, setFields] = useState<CRUDField[]>([])
  const [fieldValues, setFieldValues] = useState({})
  const { t } = useTranslation("global")
  const updateFilter = (fieldName, fieldValue) => {
    console.log(fieldName, fieldValue)

   const newObj = {...fieldValues, [fieldName]: fieldValue}
    setFieldValues(newObj)
  }

  const submit = () => {
   
    props.setFilterFields(fieldValues)
  }

  const clear = () => {
    setFieldValues({})
    props.setFilterFields({})
  }

  useEffect(() => {
    setFields(props.fields)
  }, [props.fields, props.values])

  return (
    <div className="card p-1 my-2">
      <div className="card-header p-1">
        <b>{t("filter")}</b>
      </div>
      <div className="card-body bg-light p-1 px-2">
        <div className="row">
          {fields &&
            fields.map((item: any, index:number) => {
              
              //item.value = props.values[item.name]
              console.log(fields[item.name], item.name)
              return (
                <FieldSelector
                  model={item}
                  key={index}
                  updateField={updateFilter}
                  obj={fieldValues}
                  value={fieldValues[item.name]}
                ></FieldSelector>
              )
            })}
        </div>
      </div>
      <div className="card-footer text-end p-1">
      <button
          className="btn btn-secondary btn-sm  me-2"
          onClick={async () => clear()}
          type="button"
        >
          <i className="fa fa-eraser"></i>
        </button>
        <button
          className="btn btn-primary btn-sm "
          onClick={async () => submit()}
          type="button"
        >
          <i className="fa fa-search"></i>
        </button>
      </div>
    </div>
  )
}

export default Filter

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Card, Container } from "react-bootstrap";
import RoleComponent from "./RoleComponent";
import SettingSetions from "./SettingSections";
import { useParams } from "react-router";
import UserComponent from "./UserComponent";
import ClientComponent from "./ClientComponent";
import ClientTypeComponent from "./ClientTypeComponent";

interface SettingsDTO {
  id: string;
}
const Settings = () => {
  const { id } = useParams<SettingsDTO>();
  const [section, setSection] = useState("");
  const loadedSettings = SettingSetions;

  const [t] = useTranslation("global");
  const toggle = (obj: string) => {
    setSection(obj);
  };
  return (
    <>
      {section === "users" && <UserComponent show={true} toggle={toggle} />}
      {section === "roles" && <RoleComponent show={true} toggle={toggle} />}
      {/* {section === "tanks" && <tanks show={true} toggle={toggle} />} */}
      {section === "customers" && (
        <ClientComponent show={true} toggle={toggle} />
      )}
      {section === "customerTypes" && (
        <ClientTypeComponent show={true} toggle={toggle} />
      )}

      <Container>
        <Row className="my-4">
          <Col xs={12}>
            <Row>
              <Col xs={12} md={6}>
                {/* <OrganizationBox /> */}
              </Col>
              {/* Subscription Status  */}
              <Col xs={12} md={6} className="text-end">
                {/* <CurrentSubscription/> */}
              </Col>
              <Col xs={12}>
                {Array.isArray(loadedSettings) &&
                  loadedSettings.map((mainSection, index) => {
                    if (
                      mainSection.requiredParent &&
                      mainSection.parentId !== id
                    )
                      return null;
                    return (
                      <Card className="shadow-sm mb-2" key={index}>
                        <Card.Header>
                          <Card.Title>{t(mainSection.name)}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <ul className="nav nav-pills nav-fill  flex-column ">
                            {mainSection.sections.map(
                              (subSection, subSectionIndex) => {
                                return (
                                  <li
                                    className="nav-item "
                                    key={subSectionIndex}
                                  >
                                    <button
                                      className={
                                        " btn-block btn  text-left " +
                                        (section === subSection.reference
                                          ? "btn-primary"
                                          : "btn")
                                      }
                                      onClick={() => {
                                        toggle(subSection.reference);
                                      }}
                                    >
                                      {t(subSection.label)}
                                    </button>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </Card.Body>
                      </Card>
                    );
                  })}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Settings;

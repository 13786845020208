import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../common/API";
import { Routes } from "../../routes";
import moment from "moment";

const CustomerDetails = (props) => {
  const [model, setModel] = useState(null);
  const [modelVolumes, setModelVolumes] = useState(null);
  const { id } = useParams();
  const request = async () => {
    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction(`Customers/Details/` + id);

    if (requestAPI.data.status === "ok") {
      setModel(requestAPI.data.response);
    }
  };

  const requestSales = async () => {
    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction(`Customers/volumePurchase/` + id);

    if (requestAPI.data.status === "ok") {
      setModelVolumes(requestAPI.data.response);
    }
  };

  useEffect(() => {
    console.log("Model updated", model);
  }, model);

  useEffect(() => {
    request();
    requestSales();
  }, []);
  let total = 0;
  return (
    <div className="row mx-0">
      <div className="col-12">
        <nav aria-label="breadcrumb" className="mb-2">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">
                <i className="fa fa-home"></i>
              </a>
            </li>

            <li className="breadcrumb-item">
              <a href={Routes.Customers.path}>Clientes</a>
            </li>
            <li className="breadcrumb-item">
              <a href={"#"}>Detalles</a>
            </li>
          </ol>
        </nav>
      </div>
      <div className="col-4">
        <div className="card shadow-sm">
          <div className="card-header">
            <h4>{model && model.Name}</h4>
            <p>
              <b>ID:</b> {model && model.Identificator}
            </p>
          </div>
        </div>
      </div>
      <div className="col-8">
        <div className="card shadow-sm">
          <div className="card-header">
            <h4>Compras por volumen </h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Fecha</th>
                    <th>Cantidad (gl)</th>
                  </tr>
                </thead>
                <tbody>
                  {modelVolumes &&
                    modelVolumes.map((item, index) => {
                      total += item.Amount;
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{moment(item.Date).format("yyyy-MM-dd")}</td>
                          <td>{item.Amount}</td>
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={3} className="text-uppercase">
                      Total: {total} gl
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;

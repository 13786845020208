import SettingSectionDTO from "./SettingSectionDTO";

const SettingSetions: SettingSectionDTO[] = [
  {
    name: "administration",
    parentId: "admin",
    requiredParent: false,
    sections: [
      {
        reference: "users",
        label: "users",
      },
      {
        reference: "roles",
        label: "roles",
      },
    ],
  },

  {
    name: "Operations",
    parentId: "admin",
    requiredParent: false,
    sections: [
      {
        reference: "tanks",
        label: "tanks",
      },
      {
        reference: "customers",
        label: "customers",
      },
      {
        reference: "customerTypes",
        label: "Customer Types",
      },
    ],
  },
];

export default SettingSetions;

import React from "react";


import Summary from "../components/dashboard/Summary";

const SummaryPage = () => {
	
	return <Summary/>;
};

export default SummaryPage
import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "./routes";

// pages

import Settings from "./components/Settings/Settings";
// components
import Sidebar from "./components/Common/Sidebar";
import Navbar from "./components/Common/Navbar";
import Preloader from "./components/Preloader";
import Users from "./components/Users/Users";

import Profile from "./components/Profile/Profile";
import Login from "./components/Profile/Login";
import Register from "./components/Profile/Register";
import ForgotPassword from "./components/Profile/ForgotPassword";
import Customers from "./components/Customers/index";

import Services from "./components/Services/index";
import Providers from "./components/Providers/index";
import Metters from "./components/Metters/index";
import CustomerDetails from "./components/Customers/CustomerDetails";

import InventoryPage from "./pages/InventoryPage";
import DashboardPage from "./pages/DashboardPage";
import SalesPage from "./pages/SalesPage";
import SummaryPage from "./pages/SummaryPage";
import DashboardOverview from "./components/dashboard/DashboardOverviewOld";
import ReportsPage from "./pages/ReportsPage";
import SalesRecordPage from "./pages/SalesRecordPage";
import TablePage from "./pages/TablePage";

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />

          <main className="content p-0" style={{ overflow: "hidden" }}>
            <Navbar {...props} />
            <Component {...props} />
          </main>
        </>
      )}
    />
  );
};

export default () => {
  return (
    <Switch>
      {/* pages */}
      <RouteWithSidebar
        exact
        path={Routes.SalesRecord.path}
        component={SalesRecordPage}
      />
      <RouteWithSidebar
        exact
        path={Routes.Home.path}
        component={DashboardOverview}
      />
      <RouteWithSidebar exact path={Routes.Sales.path} component={SalesPage} />
      <RouteWithSidebar
        exact
        path={Routes.DashboardSummary.path}
        component={SummaryPage}
      />
      <RouteWithSidebar
        exact
        path={Routes.DashboardPage.path}
        component={DashboardPage}
      />
      <RouteWithSidebar
        exact
        path={Routes.Inventory.path}
        component={InventoryPage}
      />

      <RouteWithSidebar
        exact
        path={Routes.Settings.path}
        component={Settings}
      />

      <RouteWithSidebar exact path={Routes.Users.path} component={Users} />

      <RouteWithSidebar
        exact
        path={Routes.Reports.path}
        component={ReportsPage}
      />
      <RouteWithSidebar
        exact
        path={Routes.Customers.path}
        component={Customers}
      />

      <RouteWithSidebar
        exact
        path={Routes.Services.path}
        component={Services}
      />
      <RouteWithSidebar
        exact
        path={Routes.Providers.path}
        component={Providers}
      />
      <RouteWithSidebar exact path={Routes.Metters.path} component={Metters} />
      <RouteWithSidebar exact path={Routes.Profile.path} component={Profile} />
      <RouteWithSidebar
        exact
        path={Routes.CustomerDetails.path}
        component={CustomerDetails}
      />
      <Route path={Routes.Table.path} component={TablePage} />
      <Route path={Routes.SignIn.path} component={Login} />
      <Route path={Routes.Register.path} component={Register} />
      <Route path={Routes.ForgotPassword.path} component={ForgotPassword} />
      {/* <RouteWithSidebar exact path={Routes.Dashboard.path} component={DashboardOverview} /> */}
      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );
};

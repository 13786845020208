
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';

import { parsedUser } from '../../common/GetCurrentUser';

import { Routes } from '../../routes';
import AddEdit from './AddEdit';
import emptyImg from "../../assets/img/illustrations/no_content.svg";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Pagination from '../Common/Pagination';
import API from '../../common/API';


const ProviderList = props => {
    const history = useHistory();
    const [Search, setSearch] = useState("");
    const [showAddEdit, setShowAddEdit] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [state, setState] = useState({
        Records: [],
        UI: {
            IsLoading: false,
            ItemSelected: null
        },
        currentPage: 0,
        Quantity: 20,
        TotalPages: 0,
        OnlyPendingToPaid: false
    })

    const onPageChanged = async obj => {
        setState({
            ...state,
            currentPage: obj
        });


        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    const theUser = parsedUser();

    useEffect(() => {
        if (theUser === null) {
            window.location = Routes.SignIn.path;
        }
        request();
    }, [state.currentPage]);

    useEffect(() => {
        request();
    }, []);


    const request = async () => {

        let Query = "Search=" + Search + "&Quantity=" + state.Quantity +
            "&Page=" + state.currentPage;

        //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
        let requestAPI = await API.getAction(`providers/${theUser.organizationId}`, Query);

        if (requestAPI.data.status === "ok") {
            setState({
                ...state,
                Records: requestAPI.data.response,

                TotalPages: requestAPI.data.quantityOfPages
            })
        }
    };
    const toggleAddEdit = async (obj, reload) => {
        setShowAddEdit(!showAddEdit);

        setSelectedItem(obj);

        if (reload) {
            await request();
        }
    }

    const deleteConfirm = async (obj) => {

        let currentUser = parsedUser();

        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to delete ' + obj.name + "?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        await API.getAction("providers/Delete/" + obj.id + "?UserId=" + currentUser.ID);
                        await request();
                    }
                },
                {
                    label: 'No',
                    //     onClick: () => alert('Click No')
                }
            ]
        });
    }

    return (<>
        {showAddEdit ?
            <AddEdit toggle={toggleAddEdit} show={showAddEdit} model={selectedItem} />
            : null}

        {props.Full ? <div className="row mb-2 mx-0">
            <div className="col ">
                <h5 className="display-4">
                    <i className="fas fa-bus"></i>&nbsp;Proveedores</h5>
            </div>
            <div className="ml-auto col-auto text-right">
                <div className='input-group'>
                    <div className='input-group-text'>
                        <i className='fa fa-search'></i>
                    </div>
                    <input type="text" className='form-control ' placeholder='filtrar'
                        value={Search}
                        onChange={async e => {
                            setSearch(e.target.value);

                            await request();
                        }}
                    />

                    <button className="btn btn-primary btn-sm" onClick={() => toggleAddEdit()}>
                        <i className="fa fa-plus" />

                    </button>
                </div>
            </div>
        </div> : null
        }

        {
            state.Records.length === 0 ?
                <div className="row">
                    <div className='col-6 mx-auto text-center'>
                        <img src={emptyImg} className="img-fluid" style={{ maxWidth: "50%" }} alt="" />
                    </div>
                </div>
                : null
        }
        {
            state.Records.length > 0 ?
                <div className="card shadow-sm">

                    {!props.Full ?
                        <div className="card-header py-1">
                            <div className="card-title">
                                <i className="fa fa-id-card " />&nbsp;
                                Proveedores</div>
                        </div>
                        : null}
                    <div className="card-body  ">
                        {state.Records.length > 0 ?
                            state.Records.map((parsedItem, index) => {

                                return (
                                    <div className="row px-0 " key={index} >
                                        <div className="col clickable" onClick={() => toggleAddEdit(parsedItem, false)}>
                                            <span>{parsedItem.name}</span>
                                            <br />
                                            <small className='text-muted'>{parsedItem.identifier}</small>
                                        </div>

                                        <div className="col-auto text-right">
                                            <div className="btn-group">

                                                <button className="btn btn-primary btn-sm"
                                                    onClick={() => toggleAddEdit(parsedItem, false)}
                                                >
                                                    <i className="fa fa-edit" />
                                                </button>
                                                <button className="btn btn-primary btn-sm" onClick={() => deleteConfirm(parsedItem)} type="button">
                                                    <i className="fa fa-trash text-danger" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <hr className="my-1" />
                                        </div>
                                    </div>
                                )
                            })
                            : null}
                    </div>
                    <div className='card-footer overflow-hidden'>
                        <Pagination totalPages={state.TotalPages} currentPage={state.currentPage} onPageChanged={onPageChanged} />
                    </div>
                </div >
                : null
        }
    </>);
}

export default ProviderList;
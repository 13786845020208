import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FormatMoney } from "../../common/helpers";

import API from '../../common/API';

import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import { parsedUser } from "../../common/GetCurrentUser";
import { Routes } from "../../routes";

const QuickAddEditSaleRecord = props => {
    const user = parsedUser();
    if (!user) {
        window.location = Routes.SignIn.path
    }
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        "id": "",
        "date": moment().format("YYYY-MM-DD"),
        "detailsTotal": 0,
        "granelTotal": 0,
        "saleTotal": 0,
        "costTotal": 0,
        "overload": 0,
        "organizationId": user.OrganizationId
    })
    const bindRemote = async () => {

        setIsLoading(true);
        let request = await API.getAction(`salesRecord/byDate?organizationId=${user.OrganizationId}&date=${props.date}`);
        setIsLoading(false);
        if (request.status === 200 && request.data.response) {
            setState(request.data.response);
        } else {
            setState({
                "id": null,
                "date": props.date,
                "detailsTotal": 0,
                "granelTotal": 0,
                "saleTotal": 0,
                "costTotal": 0,
                "organizationId": user.OrganizationId,
                "overload": 0
            });
        }
    }



    const handleSubmit = async (event) => {

        console.log("values to submit", state);

        if (!state.id || state.id.length === 0) {
            state.id = uuidv4();

        }
        state.organizationId = user.OrganizationId;
        state.date = moment(props.date).format('YYYY-MM-DD')

        let request = await API.postAction(`salesRecord/addEdit`, state);

        console.log("response from adding", request);
        if (request.status === 200) {
            await bindRemote();
        }
    };
    let totalSummary = 0;

    useEffect(() => {

        bindRemote();
    }, [props.date])

    if (!state) {
        return (<></>)
    }
    return (<>
        <Card className="shadow-sm mb-4 bg-white">
            <Card.Header className="p-1 px-2">
                <Card.Title>Registro de Venta</Card.Title>
            </Card.Header>
            <Card.Body className="bg-light p-1">
                <Row className="mx-0">

                    <Col>
                        <label>Al Detalle (gl)</label>
                        <input type="number"
                            min={0}
                            name="detailsTotal" value={state.detailsTotal ?? 0} className="form-control"
                            onChange={e => {
                                setState({
                                    ...state,
                                    detailsTotal: e.target.value
                                })
                            }}
                        />
                    </Col>
                    <Col>
                        <label>A Granel (gl)</label>
                        <input type="number"
                            min={0}
                            name="granelTotal" value={state.granelTotal ?? 0} className="form-control"
                            onChange={e => {
                                setState({
                                    ...state,
                                    granelTotal: e.target.value
                                })
                            }}
                        />
                    </Col>


                    <Col>
                        <label>Venta (USD$)</label>
                        <input type="number"
                            min={0}
                            name="saleTotal" value={state.saleTotal ?? 0} className="form-control"
                            onChange={e => {
                                setState({
                                    ...state,
                                    saleTotal: e.target.value
                                })
                            }}
                        />
                    </Col>
                    <Col>
                        <label>Costo Ops (USD$)</label>
                        <input type="number"
                            min={0}
                            name="costTotal" value={state.costTotal ?? 0} className="form-control"
                            onChange={e => {
                                setState({
                                    ...state,
                                    costTotal: e.target.value
                                })
                            }}
                        />
                    </Col>

                    {/* this information is hidden for now */}
                    {/* <Col>
                        <label>Overload</label>
                        <input type="number"
                            min={0}
                            name="overload" value={state.overload ?? 0} className="form-control"
                            onChange={e => {
                                setState({
                                    ...state,
                                    overload: e.target.value
                                })
                            }}
                        />
                    </Col> */}


                </Row>
            </Card.Body>
            <Card.Footer className="p-1 px-2">
                <Row>
                    <Col >
                        <label>TOTAL VENTA GL</label>
                        <span className="mx-2">{FormatMoney(1 * state.detailsTotal + 1 * state.granelTotal)} gl</span>
                    </Col>
                    <Col className="text-end col-auto">

                        <button className="btn btn-primary btn-sm"
                            disabled={isLoading}
                            onClick={() => handleSubmit()} type="button">
                            {isLoading ? <i className="fa fa-spinner fa-spin me-2"></i> : null}
                            Guardar
                        </button>
                    </Col>
                </Row>
            </Card.Footer>
            {/* <Card.Footer>
                Total Vendido: <b>{FormatMoney((props.totalMeters - state.GLPDetailCredit) * state.GourdesRate * 1)} HTG</b>
            </Card.Footer> */}
        </Card>

    </>);
}

export default QuickAddEditSaleRecord;
import React from "react";
import TotalPurchaseDaily from "./TotalPurchaseDaily";
import { Col, Container, Row } from "react-bootstrap";
import TotalSaleDaily from "./TotalSaleDaily";
import TotalManualSaleDaily from "./TotalManualSaleDaily";
import TotalSaleAndDelivered from "./TotalSaleAndDelivered";
import TotalPurchaseMonthly from "./TotalPurchaseMonthly";
import TotalSaleMonthly from "./TotalSaleMonthly";

const Summary = () => {
  return (
    <Container>
      <Row>
        {/* <Col xs={12} md={6}>
          <TotalPurchaseDaily />
        </Col> */}

        <Col xs={12} md={6}>
          <TotalPurchaseMonthly />
        </Col>
		
        {/* <Col xs={12} md={6}>
          <TotalSaleDaily/>
        </Col> */}
        <Col xs={12} md={6}>
          <TotalSaleMonthly />
        </Col>
		<Col xs={12} md={6}>
          <TotalManualSaleDaily />
        </Col>
		<Col xs={12} md={6}>
          <TotalSaleAndDelivered />
        </Col>
      </Row>
    </Container>
  );
};

export default Summary;

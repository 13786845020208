import React, { useEffect, useState } from 'react'
import { Routes } from '../../routes';
import MetterList from './MetterList';


const Index = props => {
    useEffect(()=>{
        document.title ="Servicios"
    })
    return (
        <div className="row">
            <nav aria-label="breadcrumb" className="mb-2">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/"><i className="fa fa-home"></i></a></li>

                    <li className="breadcrumb-item"><a href={Routes.Metters.path}>Medidores</a></li>
                    
                </ol>
            </nav>
            <div className="col-12">
                <MetterList Full/>
            </div>
        </div>
    )
}
export default Index;
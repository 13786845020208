import React, { useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faCog,
  faSignOutAlt,
  faTimes,
  faUsers,
  faBell,
  faGasPump,
  faBoxes,
  faMoneyBill,
  faMoneyBillAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Accordion, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { parsedUser } from "../../common/GetCurrentUser";
import { Routes } from "../../routes";
import logoAvocado from "../../assets/img/logo_zaboka_avocado.png";
import logo from "../../assets/img/logoLetterZaboka_w.png";
import { useTranslation } from "react-i18next";

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const { t } = useTranslation("global");
  const [user, setUser] = useState(null);

  useEffect(() => {
    setUser(parsedUser());
  }, []);
  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.Home.path}>
          {/* <Image src={ReactHero} className="navbar-brand-light" /> */}
          <img
            src={logo}
            alt="zaboka"
            className="navbar-brand-light"
            style={{ maxHeight: "22px" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                {/* <div className="user-avatar lg-avatar me-4">
                  <Image src={logoAvocado} className="card-img-top rounded-circle border-white" />
                </div> */}
                <div className="d-block">
                  <h6>Hola, {user && user.email}</h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to={Routes.SignIn.path}
                    className="text-dark"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Cerrar Sesión
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <div className="text-center">
                <img
                  src={logo}
                  className="img-fluid mb-4"
                  title="Zaboka"
                  style={{ width: "80%" }}
                ></img>
              </div>

              <NavItem
                title={t("Inicio")}
                link={Routes.Home.path}
                icon={faChartPie}
              />
              {/* <NavItem title="Resumen" icon={faChartPie} link={Routes.DashboardSummary.path} /> */}
              <NavItem
                title={t("Dashboard")}
                icon={faChartPie}
                link={Routes.DashboardPage.path}
              />
              <NavItem
                title="Inventario"
                icon={faBoxes}
                link={Routes.Inventory.path}
              />
              {/* <NavItem title="Servicios" icon={faBell} link={Routes.Services.path} /> */}
              {/* <NavItem title="Clientes" icon={faUsers} link={Routes.Customers.path} /> */}
              <NavItem
                title="Ventas"
                icon={faMoneyBill}
                link={Routes.Sales.clean}
              />
              <NavItem
                title="Resumen de Ventas"
                icon={faMoneyBillAlt}
                link={Routes.SalesRecord.clean}
              />
              <NavItem
                title="Reportes"
                icon={faChartPie}
                link={Routes.Reports.clean + "admin"}
              />
              <NavItem
                title="Configuración"
                icon={faCog}
                link={Routes.Settings.path}
              />
              {/* <NavItem title="Proveedor" icon={faBus} link={Routes.Providers.path}  /> */}
              {/* <CollapsableNavItem eventKey="Reportes/" title="Reportes" icon={faCog}>

              </CollapsableNavItem> */}
              {/* <NavItem title="Configuración" icon={faCog} link={Routes.Settings.path} /> */}
              {/* {user && user.roleId === "admin" ?
                <CollapsableNavItem eventKey="tables/" title="Administración" icon={faCog}>
                  <NavItem title="Users" link={Routes.Admin.path} />
                </CollapsableNavItem>

                : null} */}
              <div className="text-center">
                <img src={logoAvocado} style={{ height: "100px" }}></img>
              </div>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Routes } from "../../routes";
import { parsedUser } from "../../common/GetCurrentUser";

import QuickAddEditMetter from "../Metters/QuickAddEditMetter";
import moment from "moment";
import QuickAddEditVolume from "../ProductVolumes/QuickAddEditVolume";
import QuickAddEditSale from "../SalesByGallonPerDay/QuickAddEditSale";
import QuickAddEditCustomerSale from "../Customers/QuickAddEditCustomerSale";
import QuickAddEditCustomerSaleGranel from "../Customers/QuickAddEditCustomerSaleGranel";
import CalibrationTable from "../Metters/CalibrationTable";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TotalSaleAndDelivered from "./TotalSaleAndDelivered";

const DashboardOverview = () => {
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const user = parsedUser();
  const { id } = useParams();
  const history = useHistory();
  const [totalMeters, setTotalMeters] = useState(0);
  const SumMeter = (obj) => {
    let count = 0;
    Array.isArray(obj) &&
      obj.forEach((item) => {
        let amount = item.amount - item.previousAmount;
        count += amount;
      });

    setTotalMeters(count);
  };
  const [showCalibration, setShowCalibration] = useState(false);
  const toggleCalibration = () => {
    setShowCalibration(!showCalibration);
  };

  useEffect(() => {
    if (!user) {
      history.push(Routes.SignIn.path);
    }
  }, []);

  // useEffect(() => {
  //   console.log(id);
  //   if (id) {
  //     setDate(id)
  //   }

  //   if(!id || id===':id'){
  //     history.push(Routes.Dashboard.clean + moment().format('YYYY-MM-DD'))
  //     }
  // }, [id])

  return (
    <>
      {showCalibration ? (
        <CalibrationTable toggle={toggleCalibration} show={showCalibration} />
      ) : null}
      <Row className="mx-0">
        <Col className="col-auto">
          <input
            type="date"
            className="form-control d-inline"
            value={date}
            onChange={(e) => {
              //   history.push(Routes.Dashboard.clean + moment(e.target.value).format('YYYY-MM-DD'))
              setDate(e.target.value);
            }}
          />
        </Col>
        <Col className="col-auto">
          <button
            className="btn btn-primary"
            onClick={() => {
              history.push(
                Routes.Dashboard.clean + moment(date).format("YYYY-MM-DD")
              );
            }}
          >
            <i className="fa fa-search"></i>
          </button>
        </Col>
        <Col className="col-auto  ms-auto">
          <button
            className="btn btn-secondary"
            onClick={() => toggleCalibration()}
            type="button"
          >
            Mostrar tabla de calibración
          </button>
        </Col>
        <Col xs={12} className="mt-4">
          
          <Row>
            <Col xs={12} md={12}>
              <QuickAddEditMetter date={date} SumMetter={SumMeter} />
            </Col>

            <Col xs={6}>
              <QuickAddEditSale date={date} />
            </Col>

            <Col xs={12} md={12}>
              <TotalSaleAndDelivered />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DashboardOverview;

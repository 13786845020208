import React, { useEffect, useState } from "react";
import { parsedUser } from "../../common/GetCurrentUser";

import API from "../../common/API";
import moment from "moment";
import { FormatMoney } from "../../common/helpers";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
const TotalSaleYearly = (props) => {
  const { t } = useTranslation();
  const [records, setRecords] = useState([]);
  const [state, setState] = useState<any>(null);
  const user = parsedUser();
  const bindDataRemote = async () => {
    let year = moment().format("YYYY");
    let query = `organizationId=${user.OrganizationId}&year=${year}`;
    const request = await API.getAction(`tank/TotalSaleYearly?${query}`);

    console.log(request);

    setRecords(request.data.response);
  };

  useEffect(() => {
    bindDataRemote();
  }, []);

  useEffect(() => {
    let labels: string[] = [];
    console.log(records)
    if (records) {
      labels = records.map((item: any) => item.date);
      setState({
        //  [],
        labels,
        datasets: [
          {
            label: "Granel",
            data: records.map((item: any) => item.granelTotal),
            backgroundColor: "red",
          },
          {
            label: "Details",
            data: records.map((item: any) => item.detailsTotal),
            backgroundColor: "blue",
          },
          {
            label: "Total",
            data: records.map((item: any) => item.total),
            backgroundColor: "green",
          },
        ],
      });
    }
  }, [records]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options: object = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Total Delivered",
      },
    },
  };

  if (!records || !state) {
    return <></>;
  }

  return (
    <>
      <div className="card shadow-sm mb-2">
        <div className="card-header">
          <Row>
            <Col>
              <span className="display-6 d-inline text-uppercase">
                Total Entrega
              </span>
            </Col>
            <Col className="text-end">
              <h4 className="py-0 m-0">
                Total:{" "}
                {FormatMoney(
                  records.reduce((a: any, b: any) => a + b.total, 0)
                )}{" "}
                gl
              </h4>
              <small>
                Granel:{" "}
                {FormatMoney(
                  records.reduce((a: any, b: any) => a + b.granelTotal, 0)
                )}{" "}
                gl
              </small>
              <small>
                &nbsp;|&nbsp;Detalle:{" "}
                {FormatMoney(
                  records.reduce((a: any, b: any) => a + b.detailsTotal, 0)
                )}{" "}
                gl
              </small>
            </Col>
          </Row>
        </div>
        <Card.Body>
          <Col xs={12}>
            <Line options={options} data={state} />
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Año</th>
                  <th className="text-end">{t("Granel")}</th>
                  <th className="text-end">{t("Details")}</th>
                  <th className="text-end">{t("Total")}</th>
                </tr>
              </thead>
              <tbody>
                {records.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{item.date}</td>
                    <td className="text-end">
                      {FormatMoney(item.granelTotal)}{" "}
                    </td>
                    <td className="text-end">
                      {FormatMoney(item.detailsTotal)}
                    </td>
                    <td className="text-end">{FormatMoney(item.total)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Card.Body>
      </div>
    </>
  );
};

export default TotalSaleYearly;

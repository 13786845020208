
import Profile3 from "../assets/img/team/profile-picture-3.jpg";
export default Helper => {

};

export const PayrollTypes = { hourly: 4, weekly: 1, biWeekly: 2, monthly: 3 }
export const TransactionType = { percentage: 1, factor: 2, amount: 3 }

const colors = ["3f67fa"];//["3f67fa", "fbcb6d", "0e1d2c", "bdbcd9"]
Array.prototype.getRandom = function (cut) {
    var i = Math.floor(Math.random() * this.length);
    if (cut && i in this) {
        return this.splice(i, 1)[0];
    }
    return this[i];
}
export const userAvatar = (userName, size) => {
    if (userName === null) {
        return Profile3;
    }

    let spli = userName ? userName.split(' ') : [];
    let initials = "";
    spli.forEach(element => {
        if (initials.length < 2) {
            initials += element.substring(0, 1).toUpperCase();
        }
    });


    let colorToUse = colors.getRandom();
    size = size ? size : 75
    let path = "https://via.placeholder.com/" + size + "/" + colorToUse + "/ffffff/?text=" + initials;
    return path;
}

export const onlyHTMLTags = text => {
    var hold = document.createElement("div");
    hold.innerHTML = text.trim();
    return hold.innerText.length === 0;
}


export const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
}

export const payrollStatusParsed = item => {
    switch (item) {
        case 1:
            return "En progreso";
        case 2:
            return "Aprobada";
        case 3:
            return "Cancelad";
        default:
            return "-";

    }
}


export const benefitsAndDiscountParsed = item => {
    switch (item) {
        case 0:
            return "Monto";
        case 1:
            return "Porcentaje";
        case 2:
            return "Fracción";
        default:
            return "-";

    }
}


export const sortArray = (records, field) => {
    return records.sort((a, b) => {

        var nameA = typeof a[field] === "number" ? a[field] : a[field].toUpperCase(); // ignore upper and lowercase
        var nameB = typeof b[field] === "number" ? b[field] : b[field].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    });
}




//n= number
//c= comma separator
//t = houndry separator
//
export const FormatMoney = function (n, c, decimal, t) {

    var c = isNaN(c = Math.abs(c)) ? 2 : c,
        decimal = decimal === undefined ? "." : decimal,
        t = t === undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? decimal + Math.abs(n - i).toFixed(c).slice(2) : "");
};


export const NumberFormat = function (item) {
    return new Intl.NumberFormat().format(isNaN(item * 1) ? 0 : item * 1)
}


export const makeId = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const RoleComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("role") ?? ""}
      getRecordsPath={`organization/roles?OrganizationId=${user.OrganizationId}`}
      addEditRecordPath={`organization/AddEditRole?currentUserId=${user.ID}`}
      deleteRecordPath="organization/DeleteRole"
      fieldsList={[
        {
          name: "name",
          title: t("Name"),
        },
        {
          name: "admin",
          title: t("admin"),
          cssClass: 'text-center'
        },
        {
          name: "finance",
          title: t("finance"),
          cssClass: 'text-center'
        },
        {
          name: "hr",
          title: t("hr"),
          cssClass: 'text-center'
        },
        {
          name: "workshop",
          title: t("workshop"),
          cssClass: 'text-center'
        },
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "organizationId",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("Name") ?? "",
          containerClass: "col-12",
          required: true,
        },
        {
          name: "admin",
          htmlType: "select",
          dbType: "int",
          title: t("admin") ?? "",
          containerClass: "col-6",
          required: true,
          dataSourceTextField: "name",
          dataSourceValueField: "id",
          dataSourceLocal: [
            { id: 1, name: "Yes" },
            { id: 0, name: "No" },
          ]
        },

        {
          name: "finance",
          htmlType: "select",
          dbType: "int",
          title: t("finance") ?? "",
          containerClass: "col-6",
          required: true, dataSourceTextField: "name",
          dataSourceValueField: "id",
          dataSourceLocal: [
            { id: 1, name: "Yes" },
            { id: 0, name: "No" },
          ]
        },
        {
          name: "hr",
          htmlType: "select",
          dbType: "int",
          title: t("hr") ?? "",
          containerClass: "col-6",
          required: true,
          dataSourceTextField: "name",
          dataSourceValueField: "id",
          dataSourceLocal: [
            { id: 1, name: "Yes" },
            { id: 0, name: "No" },
          ]
        },
       
        {
          name: "workshop",
          htmlType: "select",
          dbType: "int",
          title: t("workshop") ?? "",
          containerClass: "col-6",
          required: true,
          dataSourceTextField: "name",
          dataSourceValueField: "id",
          dataSourceLocal: [
            { id: 1, name: "Yes" },
            { id: 0, name: "No" },
          ]
        },
      ]}
    />
  )
}

export default RoleComponent

import React, { useEffect, useState } from "react";
import { parsedUser } from "../../common/GetCurrentUser";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import API from "../../common/API";
import moment from "moment";
import { FormatMoney } from "../../common/helpers";
import { Col, Row } from "react-bootstrap";

const TotalManualSaleDaily = (props) => {
  const [from, setFrom] = useState(
    moment().add("days", -30).format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [state, setState] = useState(null);
  const [records, setRecords] = useState([]);
  const user = parsedUser();
  const [viewMode, setViewMode] = useState("graph");
  const toggleViewMode = (mode) => {
    setViewMode(mode);
  };
  const bindDataRemote = async () => {
    let query = `organizationId=${user.OrganizationId}&from=${from}&to=${to}`;
    const request = await API.getAction(`tank/TotalSale?${query}`);

    console.log(request);

    setRecords(request.data.response);
  };

  useEffect(() => {
    if (records) {
      const labels = records.map((item) =>
        moment(item.date).format("MMM DD, YYYY")
      );
      setState({
        //  [],
        labels,
        datasets: [
          {
            label: "Granel",
            data: records.map((item) => item.granelTotal),
            backgroundColor: "red",
          },
          {
            label: "Detalle",
            data: records.map((item) => item.detailsTotal),
            backgroundColor: "blue",
          },
          {
            label: "Total",
            data: records.map((item) => item.total),
            backgroundColor: "green",
          },
        ],
      });
    }
  }, [records]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Total Purchased",
      },
    },
  };
  useEffect(() => {
    bindDataRemote();
  }, []);

  useEffect(() => {
    bindDataRemote();
  }, [from, to]);

  if (!state || !records) {
    return <></>;
  }
  let totalGranel = 0,
    totalDetails = 0,
    totalTotal = 0;
  return (
    <>
      <div className="card shadow-sm mb-2">
        <div className="card-header">
          <Row>
            <Col>
              <span className="display-6 d-inline text-uppercase">
                Total Venta (GL)
              </span>
            </Col>
            <Col className="text-end">
              <div className="input-group">
                <input
                  type="date"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                  className="form-control"
                />
                <input
                  type="date"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                  className="form-control"
                />
              </div>
            </Col>
            <Col className="text-end ml-auto col-auto">
              <div className="btn-group">
                <button
                  className={
                    "btn btn-sm " +
                    (viewMode === "graph"
                      ? "btn-primary"
                      : "btn-outline-primary")
                  }
                  onClick={() => toggleViewMode("graph")}
                >
                  <i className="fas fa-chart-bar"></i>
                </button>
                <button
                  className={
                    "btn btn-sm " +
                    (viewMode === "table"
                      ? "btn-primary"
                      : "btn-outline-primary")
                  }
                  onClick={() => toggleViewMode("table")}
                >
                  <i className="fa fa-table"></i>
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="card-body p-0">
          {viewMode === "graph" && <Line options={options} data={state} />}

          {viewMode === "table" && (
            <table className="table table-striped border">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Granel</th>
                  <th>Detalle</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {records.map((item, index) => {
                  totalGranel += item.granelTotal * 1;
                  totalDetails += item.detailsTotal * 1;
                  totalTotal += item.total * 1;
                  return (
                    <tr key={index}>
                      <td>{moment(item.date).format("MMM DD, YYYY")}</td>
                      <td>{FormatMoney(item.granelTotal)}</td>
                      <td>{FormatMoney(item.detailsTotal)}</td>
                      <td>{FormatMoney(item.total)}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th>Total</th>
                  <th>{FormatMoney(totalGranel)}</th>
                  <th>{FormatMoney(totalDetails)}</th>
                  <th>{FormatMoney(totalTotal)}</th>
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default TotalManualSaleDaily;

import React, { useEffect, useState } from 'react';

import { Modal, Button, Col, Row, Form } from 'react-bootstrap';

import * as yup from 'yup';
import { Formik, useFormik } from 'formik';


import moment from 'moment';
import API from '../../common/API';

import { v4 as uuidv4 } from 'uuid';
import { parsedUser } from '../../common/GetCurrentUser';


//Validation
const validationSchema = yup.object({
    //   id: yup.string().required(),
    Name: yup.string().required(),
    //    organizationId: yup.string(),


});
//Component
const AddEdit = props => {
    const [state, setState] = useState({
        UI: {
            Validated: false
        },
    })

    const user = parsedUser();
  
   
    const handleSubmit = async (obj, event) => {
        console.log("values to submit", obj);

        if (obj.Id.length === 0) {
            obj.Id = uuidv4();
            obj.Created = moment().format("YYYY-MM-DD HH:mm:ss")

            
        }
        obj.OrganizationId = user.organizationId;
        if (obj.Identificator === null) {
            obj.Identificator = uuidv4();
        }


        let request = await API.postAction(`Customers/${props.mode}`, obj);

        console.log("response from adding", request);
        if (request.status === 200) {
            props.toggle(null,props.mode, true);
        }
    };
    const formik = useFormik({
        initialValues: props.model,
        validationSchema,
        onSubmit: handleSubmit,

    });

 
    useEffect(() => {
        setTimeout(() => {
            var focus = document.getElementsByName("name")[0];
            if (focus) {
                focus.focus();
            }
        }, 500)
    }, [])
if(!props.model){
    return(<></>)
}
    return (
        <Modal show={props.show} onHide={props.toggle} size='md' className='modalRight'>
            <Modal.Header closeButton>
                <Modal.Title>Cliente</Modal.Title>

            </Modal.Header>



            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    
                {/* LEAVE IT HERE SO WE CAN PROCCEED TO TROUBLESHOOT ANYTHING */}
                {Object.values(formik.errors).map((item, index)=>{
                    return(item);
                })}
                    <Row>
                        <Col xs={12} >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text"
                                name="Name"
                                value={formik.values.Name ?? ""} onChange={formik.handleChange} />
                            {formik.errors.Name ? <div className="invalid text-sm">{formik.errors.Name}</div> : null}
                        </Col>
                        <Col xs={12} >
                            <Form.Label>Identificador</Form.Label>
                            <Form.Control type="text"
                                name="Identificator"
                                value={formik.values.Identificator ?? ""} onChange={formik.handleChange} />
                            {formik.errors.Identificator ? <div className="invalid text-sm">{formik.errors.Identificator}</div> : null}
                        </Col>
                        <Col xs={6} >
                            <Form.Label>Télefono</Form.Label>
                            <Form.Control type="text"
                                name="Phone"
                                value={formik.values.Phone ?? ""} onChange={formik.handleChange} />
                            {formik.errors.Phone ? <div className="invalid text-sm">{formik.errors.Phone}</div> : null}
                        </Col>      
                        <Col xs={6} >
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text"
                                name="Email"
                                value={formik.values.Email ?? ""} onChange={formik.handleChange} />
                            {formik.errors.Email ? <div className="invalid text-sm">{formik.errors.Email}</div> : null}
                        </Col>
                        <Col xs={12} >
                            <Form.Label>Dirección 1</Form.Label>
                            <Form.Control type="text"
                                name="Address1"
                                value={formik.values.Address1 ?? ""} onChange={formik.handleChange} />
                            {formik.errors.Address1 ? <div className="invalid text-sm">{formik.errors.Address1}</div> : null}
                        </Col>
                        <Col xs={12} >
                            <Form.Label>Dirección 2</Form.Label>
                            <Form.Control type="text"
                                name="Address2"
                                value={formik.values.Address2 ?? ""} onChange={formik.handleChange} />
                            {formik.errors.Address2 ? <div className="invalid text-sm">{formik.errors.Address2}</div> : null}
                        </Col>

                        <Col xs={6} >
                            <Form.Label>Ciudad</Form.Label>
                            <Form.Control type="text"
                                name="City"
                                value={formik.values.City ?? ""} onChange={formik.handleChange} />
                            {formik.errors.City ? <div className="invalid text-sm">{formik.errors.City}</div> : null}
                        </Col>
                        <Col xs={12} >
                            <Form.Label>País</Form.Label>
                            <Form.Control type="text"
                                name="Country"
                                value={formik.values.Country ?? ""} onChange={formik.handleChange} />
                            {formik.errors.Country ? <div className="invalid text-sm">{formik.errors.Country}</div> : null}
                        </Col>
                     

                    </Row>

                </Modal.Body>
                <Modal.Footer className='p-1'>
                    <Button variant="primary" type="submit" className="btn-sm"
                        disabled={state.UI.isLoading}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>
    )
}

export default AddEdit;
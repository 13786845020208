import axios, { AxiosResponse } from "axios"
import Settings from "./Settings"
import GetCurrentUser from "./GetCurrentUser"

import { OptionsInterface } from "./API.interface"

let options: OptionsInterface = {
  headers: { "Content-Type": "application/json", accept: "text/plain" },

  crossdomain: true,
}

axios.interceptors.request.use(config => {
  // log a message before any HTTP request is sent
  console.log("The request ==== ", config)
  return config
})
const API = {
  postAction: async ( endpoint: string, params?: object, newOpts?: OptionsInterface ): Promise<AxiosResponse> => {
    let user = GetCurrentUser()
    if (user) {
      options = {
        headers: {
          "Content-Type": "application/json",
          accept: "text/plain",
          Authorization: "Bearer " + user,
        },

        crossdomain: true,
      }
    }
    return axios.post(
      Settings.BasePathForAPI + endpoint,
      params  ? params : null,
      newOpts ? newOpts : options
    )
  },
  getAction: async ( endpoint: string, params?: string, newOpts?: OptionsInterface ): Promise<AxiosResponse> => {
    let user = GetCurrentUser()
    if (user) {
      options = {
        headers: {
          "Content-Type": "application/json",
          accept: "text/plain",
          Authorization: "Bearer " + user,
        },

        crossdomain: true,
      }
    }
    return axios.get(
      Settings.BasePathForAPI +
        endpoint +
        (params  ? "?" + params : ""),
      newOpts  ? newOpts : options
    )
  },

  postActionExternal: async ( endpoint: string, params?: string, newOpts?: OptionsInterface ): Promise<AxiosResponse> =>
    axios.post(
      endpoint,
      params ? params : null,
      newOpts  ? newOpts : options
    ),
  getActionExternal: async (endpoint: string, params?: string, newOpts?: OptionsInterface ): Promise<AxiosResponse> => {
    let results = axios.get(endpoint, newOpts)
  
    return results
  },
}

export default API

import React, { useEffect, useState } from "react";
import { parsedUser } from "../../common/GetCurrentUser";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import API from "../../common/API";
import moment from "moment";
import { FormatMoney } from "../../common/helpers";
import { Card, Col, Row } from "react-bootstrap";

const TotalPurchaseYearly = (props) => {
  
  
  const [state, setState] = useState(null);
  const [records, setRecords] = useState([]);
  const user = parsedUser();
  const bindDataRemote = async () => {
    let query = `organizationId=${user.OrganizationId}&year=${moment}`;
    const request = await API.getAction(`tank/TotalPurchaseYearly?${query}`);

    console.log(request);

    setRecords(request.data.response);
  };
  useEffect(() => {
    bindDataRemote();
  }, []);


  if ( !records) {
    return <></>;
  }

  return (
    <>
      <div className="card shadow-sm mb-2">
        <div className="card-header">
          <Row>
            <Col>
              <span className="display-6 d-inline text-uppercase">
                Compra Coastal por año
              </span>
            </Col>
            <Col className="text-end">
              <h4>
                Total:{" "}
                {FormatMoney(records.reduce((a:any, b:any) => a + b.purchase, 0))} gl
              </h4>
            </Col>
        
          </Row>
        </div>
      </div>
    </>
  );
};

export default TotalPurchaseYearly;

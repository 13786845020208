import React from "react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"

interface PropsDTO {
  totalPages: number

  currentPage: number
  onPageChanged: any

  totalRecords?: number | null
}

const Pagination = (props: PropsDTO) => {
  console.log(props)
  const { t } = useTranslation("global")
  let pageNumbers: number[] = []
  for (let i = 0; i < props.totalPages; i++) {
    pageNumbers.push(i)
  }

  const renderPageNumbers = () => {
    if (props.totalPages > 5) {
      pageNumbers = []
      //We need to split this result and generate it again
      if (props.currentPage + 4 > props.totalPages) {
        for (let i = props.totalPages - 5; i <= props.totalPages - 1; i++) {
          pageNumbers.push(i)
        }
      } else {
        for (let i = props.currentPage; i <= props.currentPage + 4; i++) {
          pageNumbers.push(i)
        }
      }
    }
    return pageNumbers.map(number => {
      return (
        <li
          className={
            props.currentPage === number
              ? "page-item mx-2 active"
              : "page-item mx-2 "
          }
          key={number}
          id={number + ""}
        >
          <button
            className={
              (props.currentPage === number ? "btn-primary " : "") +
              "btn btn-sm"
            }
            onClick={() => {
              props.onPageChanged(number)
            }}
          >
            {number + 1}
          </button>
        </li>
      )
    })
  }

  return (
    <Row>
      <Col>
        <ul className="pagination pg-blue">
          {props.currentPage > 0 ? (
            <li className="page-item mx-2 ">
              <button
                className="btn btn-sm"
                type="button"
                onClick={() => props.onPageChanged(0)}
              >
                &laquo;&laquo;
              </button>
            </li>
          ) : null}

          {props.currentPage > 0 ? (
            <li className="page-item mx-2 ">
              <button
                className="btn btn-sm"
                type="button"
                onClick={() => props.onPageChanged(props.currentPage - 1)}
              >
                &laquo;
              </button>
            </li>
          ) : null}

          {renderPageNumbers()}

          {props.currentPage < props.totalPages - 1 ? (
            <li className="page-item mx-2 ">
              <button
                className="btn btn-sm"
                type="button"
                onClick={() => props.onPageChanged(props.currentPage + 1)}
              >
                &raquo;
              </button>
            </li>
          ) : null}
          {props.currentPage < props.totalPages - 1 ? (
            <li className="page-item mx-2 ">
              <button
                className="btn btn-sm"
                type="button"
                onClick={() => props.onPageChanged(props.totalPages - 1)}
              >
                &raquo;&raquo;
              </button>
            </li>
          ) : null}
        </ul>
      </Col>
      <Col className="text-end py-2 ">
        <small className="text-muted">
          {t("Page")} {props.currentPage + 1} {t("of")} {props.totalPages}
        </small>
        {props.totalRecords && (
          <span className="mx-2">
            {t("Total Records")} {props.totalRecords}
          </span>
        )}
      </Col>
    </Row>
  )
}
export default Pagination
